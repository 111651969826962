import {notifications} from '@mantine/notifications';
import {
  COLOR_ERROR_1,
  COLOR_ERROR_2,
} from '../../../config/constants/colorCodes';

const PLACEHOLDER_TITLE = 'エラー';
const PLACEHOLDER_MESSAGE = '正しく保存されませんでした';

const TITLE_NETWORK_ERROR = 'ネットワークエラー';
const MESSAGE_NETWORK_ERROR =
  '正しく保存されませんでした。通信環境をご確認ください。';

type Props = {
  title?: string;
  message?: string;
  isNetworkError?: boolean;
};

export const showErrorSnackBar = ({
  isNetworkError = false,
  title = PLACEHOLDER_TITLE,
  message = PLACEHOLDER_MESSAGE,
}: Props) => {
  notifications.show({
    title: isNetworkError ? TITLE_NETWORK_ERROR : title,
    message: isNetworkError ? MESSAGE_NETWORK_ERROR : message,
    styles: () => ({
      root: {
        backgroundColor: COLOR_ERROR_1,
        borderColor: COLOR_ERROR_1,
        color: '#fff',
        '&::before': {backgroundColor: '#fff'},
      },
      title: {color: '#fff'},
      description: {color: '#fff'},
      closeButton: {
        color: '#fff',
        '&:hover': {backgroundColor: COLOR_ERROR_2},
      },
    }),
  });
};
