import {useDisclosure} from '@mantine/hooks';
import {Notification, Transition} from '@mantine/core';
import {
  MdOutlinePlaylistAddCheck,
  MdOutlinePlaylistAdd,
  MdCheck,
} from 'react-icons/md';
import {useState} from 'react';
import {useAuth} from '../../../core/usecases/useAuth';
import {NoLoginAlertModal} from '../../../shared/components/NoLoginAlertModal';
import {useHandleCalendar} from '../usecases/useHandleCalendar';
import {showErrorSnackBar} from '../../../shared/components';

type Props = {
  scheduleId: number;
  isRegisteredInital: boolean;
  registeredCountInitial: number;
  isSP?: boolean;
};

export const ScheduleButton: React.FC<Props> = ({
  scheduleId,
  isRegisteredInital,
  registeredCountInitial,
  isSP = false,
}: Props) => {
  const {isSignedIn} = useAuth();
  const [opened, {open, close}] = useDisclosure(false);
  const {isRegistered, handleCalendar} = useHandleCalendar({
    scheduleId,
    isRegisteredInital,
    registeredCountInitial,
  });

  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);

  const handleButtonClick = async () => {
    if (!isSignedIn) {
      open();
      return;
    }

    try {
      setButtonDisabled(true);
      await handleCalendar();
      setButtonDisabled(false);
    } catch {
      setErrorOccurred(true);
      showErrorSnackBar({
        isNetworkError: false,
        title: '更新処理に失敗しました',
        message:
          '通信状況をご確認ください。Googleカレンダー連携を設定されている場合は、一度解除していただき再連携をお願いいたします。',
      });
    }
  };

  return (
    <>
      <button
        className={`border-collapse border-[1px] border-primary-1 rounded-4 ${errorOccurred ? 'text-red-600' : 'text-primary-1'} ${isRegistered ? 'bg-primary-1 text-white' : 'bg-white text-primary-1'} py-[3px] relative ${isSP ? 'w-[73px] h-[26px] pt-[5px]' : 'w-[90px]'} flex justify-center items-center hover:opacity-80`}
        disabled={isButtonDisabled || errorOccurred}
        onClick={handleButtonClick}
      >
        <sup
          className={`text-base absolute ${isSP ? 'top-[3px]' : 'top-[5px]'} left-4`}
        >
          +
        </sup>
        <span className="text-xs leading">
          {errorOccurred
            ? '更新エラー'
            : isButtonDisabled
              ? '更新中'
              : isRegistered
                ? '登録済み'
                : '予定を追加'}
        </span>
      </button>
      <NoLoginAlertModal opened={opened} onClose={close} />
    </>
  );
};

// 新デザインのスケジュールボタン
// 使われている場所のデザイン変更完了後こちらに統合する
export const ScheduleButtonNewDesign: React.FC<Props> = ({
  scheduleId,
  isRegisteredInital,
  registeredCountInitial,
  isSP = false,
}: Props) => {
  const {isSignedIn} = useAuth();
  const [opened, {open, close}] = useDisclosure(false);
  const {isRegistered, handleCalendar, notificationOpened} = useHandleCalendar({
    scheduleId,
    isRegisteredInital,
    registeredCountInitial,
  });
  const baseClasses =
    'relative flex justify-center items-center hover:opacity-50';
  const registeredClasses = isRegistered ? 'text-success-2' : 'text-black-2';
  const sizeClasses = isSP ? '' : 'w-[90px]';

  return (
    <div className="relative">
      <button
        className={`${baseClasses} ${registeredClasses} ${sizeClasses}`}
        onClick={async () => {
          if (!isSignedIn) {
            open();
            return;
          }

          return handleCalendar();
        }}
      >
        {isSP ? (
          isRegistered ? (
            <div className="border border-success-2 bg-success-2 text-white rounded-full p-4">
              <MdOutlinePlaylistAddCheck size={28} />
            </div>
          ) : (
            <div className="border border-black-2 rounded-full p-4">
              <MdOutlinePlaylistAdd size={28} />
            </div>
          )
        ) : (
          <span className="text-base font-bold flex items-center gap-2">
            {isRegistered ? (
              <>
                <MdOutlinePlaylistAddCheck size={20} />
                追加済み
              </>
            ) : (
              <>
                <MdOutlinePlaylistAdd size={20} />
                予定を追加
              </>
            )}
          </span>
        )}
      </button>
      <div className="absolute pc:top-24 top-36 right-0 w-[260px] mt-2">
        <Transition
          mounted={notificationOpened}
          transition="fade"
          duration={500}
        >
          {(styles) => (
            <Notification
              style={styles}
              icon={<MdCheck />}
              color="teal"
              radius="md"
              title="カレンダーに追加しました！"
              withCloseButton={false}
            />
          )}
        </Transition>
      </div>
      <NoLoginAlertModal opened={opened} onClose={close} />
    </div>
  );
};
